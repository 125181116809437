.nsm-dialog {
  max-width: 800px;
  .nsm-content {
    background-color: #353535;
  }
}

p.error {
  color: rgb(255, 74, 74);
}

.card-pagination {
  ul.pagination {
    li {
      a {
        border: unset;
      }
    }
    li.active {
      a {
        background-color: unset;
        border: unset;
        color: #fea116;
        padding: 8px 16px;
      }
    }
  }
}
.book-now {
  &:hover {
    color: #fff;
  }
}

.fc {
  .fc-button-primary {
    background-color: #fff !important;
    border-color: #eee !important;
    color: #212529 !important;
    &:hover {
      color: #fff !important;
      border-color: #eee !important;
      background-color: #e3455a !important;
    }
  }
  .fc-button-primary.fc-button-active {
    color: #fff !important;
    border-color: #eee !important;
    background-color: #e3455a !important;
  }
}

.card-img-top {
  height: 285px;
}
.img-fit {
  object-fit: fill;
}
.img-fill {
  object-fit: fill;
}

.mr-inline-ar {
  margin-inline: 0px;
}
.mr-inline-en {
  margin-inline: 20px;
}

.card-img-top-tutor {
  height: 421px;
}

.app-certificate {
  width: 100%;
  display: flex;
}

.outcome {
  // float: left;
  width: 100%;
  min-height: 24px;
  text-align: center;
}

// .success,
// .error {
//   display: none;
//   font-size: 20px;
// }

.success.visible {
  display: inline;
}

// .error {
//   &.visible {
//     display: block;
//     padding: 8px;
//   }
//   background-color: #ffe2e5;
//   width: 100%;
//   color: red;
// }

.success {
  color: #666ee8;

  .token {
    font-weight: 500;
    font-size: 13px;
  }
}

.edit-profile-form {
  .form-control {
    border: solid #ccc 1px;
    padding: 5px;
    height: calc(1.5em + 0.75rem + 5px);
  }
}

.pn-ProductNav_Link.active {
  color: #e3455a;
  border-bottom: 4px solid #e3455a;
  padding: 0.78em 1em !important;
}

.font-xs.active {
  color: #e3455a;
}

.font-sm.active {
  color: #e3455a;
}

.btn-upload-avatar {
  position: absolute;
  right: 5px;
  top: 5px;
  display: none;
  color: #e1245a;
}

.btn-delete-avatar {
  position: absolute;
  right: 10px;
  top: 30px;
  display: none;
  color: #e1245a;
}

.dashboard-profile > .box-image {
  width: 150px;
  position: relative;
  display: block;
  &:hover {
    .btn-upload-avatar {
      display: block !important;
    }
    .btn-delete-avatar {
      display: block !important;
    }
  }
}

.table-img {
  height: 50px;
}

.input-border {
  .ng-select {
    .ng-select-container {
      display: block;
      width: 100%;
      height: calc(1.5em + 0.75rem + 25px);
      padding: 1em 1.5em;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #808080;
      background-color: #fff;
      background-clip: padding-box;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      border: 1px solid #808080;
      border-radius: 0.3em;
      height: calc(1.5em + 0.75rem + 17px);
      color: #808080;
      .ng-value-container {
        padding: 0;
        .ng-placeholder {
          // position: unset !important;
          padding: unset !important;
          top: 15px;
        }
        .ng-input {
          padding: unset !important;
        }
      }
    }
  }
}

.table {
  th {
    padding-right: 30px;
    position: relative;
    font-size: 14px;
    a.sort-desc {
      right: 1.2em;
    }
    a.sort-asc {
      right: 0.5em;
    }
    a.sort {
      position: absolute;
      font-size: 9px;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      color: #000;
      opacity: 0.3;
    }
    a.sort.active {
      opacity: 1;
    }
  }
}

.add-certification-form {
  .form-control {
    border: solid #ccc 1px;
    padding: 5px;
    height: calc(1.5em + 0.75rem + 5px);
  }
}

.calendar-popup {
  position: absolute;
  top: 100%;
  background-color: #fff !important;
  z-index: 10;
}

.custom-day {
  text-align: center;
  padding: 0.185rem 0.25rem;
  display: inline-block;
  height: 2rem;
  width: 2rem;
}

.custom-day.focused {
  background-color: #e6e6e6;
}
.custom-day.range,
.custom-day:hover {
  background-color: #e3455a;
  color: white;
}

.custom-day.faded {
  background-color: #ffa1ae;
}

.btn-calendar {
  // border: solid #ccc 1px;
  // &:hover {
  //   background-color: #e1245a;
  //   i {
  //     color: #fff;
  //   }
  // }
  cursor: pointer;
}
.btn-pay-now {
  .spinner-border {
    width: 1rem;
    height: 1rem;
  }
}

.box-conversation {
  padding: 1em;
  img {
    max-width: 100%;
    border-radius: 50%;
  }

  .inbox_people {
    background: #f8f8f8 none repeat scroll 0 0;
    float: left;
    overflow: hidden;
    width: 40%;
    border-right: 1px solid #c4c4c4;
  }

  .inbox_msg {
    border: 1px solid #c4c4c4;
    clear: both;
    overflow: hidden;
  }

  .top_spac {
    margin: 20px 0 0;
  }

  .recent_heading {
    float: left;
    width: 40%;
  }

  .srch_bar {
    display: inline-block;
    text-align: right;
    width: 100%;
    .input-group {
      align-items: center;
    }
  }

  .headind_srch {
    padding: 0px;
    overflow: hidden;
    border-bottom: 1px solid #c4c4c4;
  }

  .recent_heading h4 {
    color: #05728f;
    font-size: 21px;
    margin: auto;
  }

  .srch_bar {
    input {
      border: 1px solid #cdcdcd;
      border-width: 0 0 1px 0;
      width: 100%;
      padding: 2px 0 4px 6px;
      background: none;
    }

    .input-group-addon {
      button {
        background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
        border: medium none;
        padding: 0;
        color: #707070;
        font-size: 18px;
      }
      position: absolute;
      right: 0;
      z-index: 9;
    }
  }

  .chat_ib {
    h5 {
      font-size: 15px;
      color: #464646;
      margin: 0 0 8px 0;

      span {
        font-size: 13px;
        float: right;
      }
    }

    p {
      font-size: 14px;
      color: #000000;
      margin: auto;
      small {
        font-size: 14px;
      }
    }
  }

  .chat_img {
    float: left;
    width: 11%;
    border-radius: 50%;
  }

  .chat_ib {
    float: left;
    // padding: 0 0 0 15px;
    width: 88%;
  }

  .chat_people {
    overflow: hidden;
    clear: both;
  }

  .chat_list {
    border-bottom: 1px solid #c4c4c4;
    margin: 0;
    padding: 18px 16px 10px;
    cursor: pointer;
    position: relative;
  }

  .inbox_chat {
    height: 550px;
    overflow-y: scroll;
  }

  .active_chat {
    background: #ffa1ae;
  }

  @media (max-width: 425px) {
    max-height: 500px;
    overflow: auto;
  }
  @media (max-width: 1080px) {
    .chat_ib {
      .conversation-header {
        font-size: 18px !important;
      }
      .chat_date {
        font-size: 14px !important;
      }
    }
  }
}

.box-message {
  padding: 1em;
  .incoming_msg_img {
    display: inline-block;
    width: 6%;
  }

  .received_msg {
    display: inline-block;
    padding: 0 0 0 10px;
    vertical-align: top;
    width: 92%;
  }

  .received_withd_msg p {
    background: #a5a5a5 none repeat scroll 0 0;
    border-radius: 3px;
    color: #fff;
    font-size: 14px;
    margin: 0;
    padding: 5px 10px 5px 12px;
    width: 100%;
  }

  .time_date {
    color: #747474;
    display: block;
    font-size: 12px;
    margin: 8px 0 0;
  }

  .received_withd_msg {
    width: 57%;
  }

  .mesgs {
    // padding: 25px;
    width: 100%;
  }

  .sent_msg p {
    background: #e3455a none repeat scroll 0 0;
    border-radius: 3px;
    font-size: 14px;
    margin: 0;
    color: #fff;
    padding: 5px 10px 5px 12px;
    width: 100%;
  }

  .outgoing_msg {
    overflow: hidden;
    margin: 26px 0 26px;
  }

  .sent_msg {
    float: right;
    width: 46%;
  }

  .input_msg_write input {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: medium none;
    color: #4c4c4c;
    font-size: 15px;
    min-height: 48px;
    width: 100%;
  }

  .type_msg {
    border-top: 1px solid #c4c4c4;
    position: relative;
  }

  .msg_send_btn {
    background: #e3455a none repeat scroll 0 0;
    border: medium none;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    font-size: 17px;
    height: 33px;
    position: absolute;
    right: 0;
    top: 11px;
    width: 33px;
  }

  .messaging {
    padding: 0 0 50px 0;
  }

  .msg_history {
    height: 516px;
    overflow-y: auto;
  }
}

.topic-form {
  .switch {
    width: 85px;
    input {
      &:checked + .slider {
        background-color: #e3455a;
      }

      &:checked + .slider-active {
        background-color: #6245e3;
      }

      &:focus + .slider {
        box-shadow: 0 0 1px #ccc;
      }

      &:checked + .slider:before {
        -webkit-transform: translateX(51px);
        -ms-transform: translateX(51px);
        transform: translateX(51px);
      }
    }
    .public-text {
      position: absolute;
      left: 8px;
      color: #fff;
      font-weight: bold;
      top: 5px;
    }

    .close-text {
      position: absolute;
      right: 8px;
      color: #fff;
      font-weight: bold;
      top: 5px;
    }
  }
}

.rating-stars {
  &.font-xs {
    li i,
    span {
      font-size: 0.95em !important;
    }
  }

  &.font-lg li i {
    font-size: 1.5em !important;
  }
}
p.more,
p.more1 {
  display: initial;
}

.img-flag-tutor {
  width: 25px;
  height: 25px;
  // float: right;
  display: inline-block;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    border: 1px solid #ececec;
  }
}
a[href="https://froala.com/wysiwyg-editor"],
a[href="https://www.froala.com/wysiwyg-editor?k=u"]
{
  display: none !important;
  position: absolute;
  top: -99999999px;
}

.text-line-through {
  -webkit-text-decoration-line: line-through; /* Safari */
  text-decoration-line: line-through;
  color: #ccc;
}

.hidden-150 {
  height: 150px;
  overflow-y: hidden;
  position: relative;
}

.bottom-0 {
  bottom: 0;
}
.right-0 {
  right: 0;
}
.left-0 {
  left: 0;
}
.align-center {
  align-items: center;
}

// .slick-next {
//   &:before {
//     filter: alpha(opacity=100);
//     -moz-opacity: 0.9;
//     -khtml-opacity: 0.9;
//     opacity: 0.9;
//   }
//   &:hover {
//     filter: alpha(opacity=100);
//     -moz-opacity: 0.9;
//     -khtml-opacity: 0.9;
//     opacity: 0.9;
//   }
// }

.hyperlink {
  color: #000 !important;
  &:hover {
    color: #007bff !important;
  }
}

.slick-track {
  margin-left: unset;
  margin-right: unset;
}

.text-secondary2 {
  color: #6c757d !important;
  margin-bottom: 0.5em;
}

.dot-message {
  color: red;
  position: absolute;
  right: 0;
  top: 5px;
  font-size: 8px;
}

.btn-zoom {
  cursor: pointer;
  &:hover {
    color: #e3455a;
  }
}


.form-inline {
  width: 100%;
  label {
    width: 100%;
  }
}

// .icon-search-bar {
//   position: absolute;
//   z-index: 9999;
//   left: 0;
//   height: 100%;
//   cursor: pointer;
// }

// .input-search-bar {
//   border-top-left-radius: 4em !important;
//   border-bottom-left-radius: 4em !important;
//   padding-left: 4em;
// }

.input-list {
  margin: 0px 20px 0px 0px;
  .input-group {
    overflow: hidden;
    .input-group-append {
      height: 100%;
      z-index: 9999;
    }
  }
}

.position-right {
  position: absolute;
  right: 0 !important;
  left: unset !important;
}

.position-left {
  position: absolute;
  left: 0 !important;
  right: unset !important;
}

.profile-card {
  position: relative;
}

.image-topic,
.image-course {
  position: relative;
  .avatar-profile {
    bottom: -2.5em;
    left: 1em;
  }

  .avatar-profile.position-right {
    right: 1em;
  }
}

.cate-profile-name.text-right {
  font-size: 1.1em;
  padding-top: 2.3em;
  padding-right: 4.2em;
  padding-left: unset;
}

.profile {
  position: relative;
  .avatar-profile {
    bottom: -2.5em;
    left: 1em;
  }

  .avatar-profile.position-right {
    right: 1em;
  }
}

.slides {
  width: 100%;
}

.pr-10 {
  padding-right: 10em;
}

.pl-10 {
  padding-left: 10em;
}

.pr-12 {
  padding-right: 12em;
}

.pl-12 {
  padding-left: 12em;
}

/****************** Ayman Edits *******************/

.card-scroll {
  height: 260px;
  overflow-y: auto;
}
.card-scroll-big {
  max-height: 400px;
  overflow-y: auto;
}
.card-scroll-small {
  height: 230px;
  overflow-y: auto;
}
.no-scroll {
  overflow-y: inherit;
}
.text-mr-inline {
  margin-inline: 8px;
}

ul.list-more-text {
  display: flex !important;
  flex-direction: column !important;
}

.bg-danger {
  color: #fff;
}
.change-color {
  color: #fff;
  font-weight: bold;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.small-width {
  width: 500px !important;
}
.fixed-width {
  width: 375px !important;
}

.fix-height {
  height: 220px;
}

.align-center-ltr {
  text-align: center;
  direction: ltr;
}



// Starter Page



// Courses
/******* Card Topic *******/
/******* Card Course *******/
/******* Same Design *******/

.courses-page,
.topics-list-page {
  padding-bottom: 50px;
}

// Heart Color
.system-add-to-favourite-icon {
  color: #dc3545;
}

.system-add-to-favourite-div-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse;
}
.system-add-to-favourite-div {
  cursor: pointer;
}
.system-add-to-buy-div {
  cursor: pointer;
  color: $success;
}



.tab-pane {
  .no-data {
    text-align: center;
    margin: 20px 0;
    font-weight: bold;
    color: #707070;
  }
}

.testimonials-card {
  .card-body {
    h6 {
      @include customTooltip(320px, inline-block, 0px);
    }
  }
}

.odd,
.list-schedule,
.transaction-table,
.course-listing {
  td.name,
  td.subject,
  td.grade {
    p {
      @include customTooltip(140px, inline-block, 0px);
      margin-bottom: 0 !important;
    }
  }
}

.bank-table,
.my-lesson-table,
.my-courses-list {
  td.name,
  td.subject,
  td.bankName,
  td.bankAddress {
    p {
      @include customTooltip(130px, inline-block, 0px);
      margin-bottom: 0 !important;
    }
  }
}
.my-lesson-table {
  td {
    padding: 0.7rem;
  }
}
.student-transaction,
.refund-table {
  td.teach-name,
  td.topic-name,
  td.subject-name,
  td.course-name,
  td.reason {
    p {
      @include customTooltip(120px, inline-block, 0px);
      margin-bottom: 0 !important;
    }
  }
}

.class-price-text {
  font-size: 15px;
}
.class-price-money {
  font-size: 13px;
}
.text-wrapping {
  width: 180px;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-inline: 15px;
  display: inline-block;
}

// Modal Edit
.modal-content {
  .modal-container-div {
    .modal-header {
      padding: 0.5rem 1rem !important;
    }
    .modal-body {
      padding: 0.4rem !important;
      .edit-padding {
        padding: 1em;
      }
    }
  }
}

// ------ All Media Query I Work On It In Here

// __applyCoupon
.__applyCoupon {
  margin-top: 25px;

  span.btn-blue {
    border-radius: 0.4em !important;
    margin: 0 !important;
  }

  input {
    border-radius: 0.5em !important;
    height: 55px;
  }
  .input-group-append {
    margin-inline-start: 10px;
  }
  .form-control {
    padding: 10px;
  }
}

.__courseDetail {
  .card-horizontal {
    padding: 1.5em;
  }

  .form-control {
    padding: 10px;
  }
}

.tutuors-page {
  padding-bottom: 20px;
  @media (max-width: 375px) {
    .row.p-3 {
      padding: 0 !important;
    }
  }
  @media (min-width: 765px) and (max-width: 770px) {
  }
}

.learn-with-us {
  .h-100 {
    height: 364px !important;
  }

  @media (max-width: 375px) {
    .h-100 {
      height: 402px !important;
    }
  }
}

.Course-data-table {
  .customSelect {
    width: 90px !important;
  }
  @media (max-width: 575.98px) {
    .custom-select.form-control {
      width: auto !important;
    }

    .form-inline {
      justify-content: center;
    }
  }

  thead {
    // display: inline-block;
    width: 100%;
  }
  .row {
    align-items: center;
  }
}

// date-range {
//   .form-control {
//         padding: 10px;
//       }
// }
.__tutor_card {
  .card-img-top {
    height: 285px !important;
  }
  // landscape Medai ________
  @media screen and (orientation: landscape) and (min-device-width: 319px) and (max-device-width: 900px) {
  }
}

// courses Coupon
.courses-table {
  .table {
    thead {
      th {
        padding-right: 0;
      }
    }
  }
}

// appointments Info
.appointmentsInfo {
  @media (max-width: 575.98px) {
    .table td {
      padding: 20px 11px;
      text-align: right !important;
    }
  }
}

// Start Genral Class ____________________________________________________
.card-horizontal {
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 3em;
  border-radius: 0.5em;
  background-color: #ffffff;

  .img-square-wrapper {
    text-align: center;

    img {
      width: 100%;
    }

    @media (min-width: 375px) and (max-width: 767px) {
      img {
        width: 50%;
      }
    }
  }

  h4.font-weight-bold {
    margin: 15px 0;
  }
  .flex-column {
    align-items: flex-start;

    a {
      width: 100%;

      h5 {
        display: inline-block;
      }
    }

    .card-text {
      font-size: 14px;
    }
  }

  @media (max-width: 992px) {
    padding: 1em;

    .btn {
      width: 100%;
      margin: 0 0 10px 0 !important;
    }
  }
}

.__reviews {
  &-AddFeedback {
    a {
      font-weight: 500;
      font-size: 24px;
      display: inline-block;
      width: 100%;
      border-radius: 2px !important;
      text-align: center;
      color: red;
    }
  }

  &-Counter {
    // border-bottom: 1px solid #dee2e6;
    border-radius: 3px;
    align-content: unset;
    align-items: center;
    margin-top: 20px;

    .row {
      align-items: center;
    }

    .starsRate {
      float: inherit !important;
      text-align: center !important;
    }
    h5.text-secondary {
      text-align: center !important;
    }
  }
}

.teacherProfileAction {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 425px) {
    margin-bottom: 25px;
    a {
      width: 100%;
    }
  }
}

.slot-btn {
  background-color: #5be2bf !important;
  padding: 13px 34px !important;
  color: #ffffff;
  border-radius: 3px !important;
  font-size: 1.1em;
  font-weight: 500 !important;
  width: 100%;
  text-align: center;
  margin-bottom: 16px !important;
}

.alert-danger {
  width: 70%;
  margin: 0 auto;
}

.cv_Data_row {
  display: flex;
  width: 100%;
  .cv_Date {
    margin-inline-end: 30px;

    p {
      display: inline-block;
      width: 100px;
    }
  }

  @media (max-width: 576px) {
    display: block;
  }
}

.Textscroll {
  max-height: 100px;
  overflow: auto;
}

._dflex {
  display: flex;
}
.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

// Start Icon Buttons Genral Class_____________________________________
._btn_square_icon {
  font-size: 1em;
  font-weight: 600;
  border-radius: 0.4em;
  box-shadow: 0 5px 10px 0 #39323233;
  text-align: center;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  padding: 13px;
  border: 0;
}
._btn_square_icon.calendar {
  box-shadow: none;
}
._btn_icon_eye,
button._btn_icon_eye {
  color: #808080;
  border: 0;
  background: transparent;
}
// Icon Buttons Genral Class_____________________________________

.myaccordion {
  .card-header {
    padding: 10px !important;
  }

  @media (max-width: 375px) {
  }
}

button.btn-collapse {
  box-shadow: inherit;
  font-size: 18px;

  p {
    margin-inline-start: 30px !important;
  }

  @media (max-width: 375px) {
    display: flex !important;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 !important;
  }
  p {
    margin-inline-start: 0;
    margin-top: 15px;
  }
}
// End Genral Class ____________________________________________________

// Start Dashboard Profile Media Query

@media (max-width: 500px) {
  .users-profile-page {
    .dashboard-profile {
      display: flex;
      flex-direction: column;
      align-items: center;

      .pt-1.text-right.pr-12 {
        text-align: center !important;
        padding: 0;
      }
    }
  }
}
._profileDetails {
  .edit-profile-form {
    .d-flex {
      align-items: flex-start !important;
      flex-direction: column !important;
      justify-content: flex-start !important;
    }
    .justify-content-between {
      justify-content: flex-start !important;
    }
    .align-items-center {
      align-items: flex-start !important;
    }
  }
}
// End Dashboard Profile Media Query

//Start Genral Media ____________________________________________________

@media (max-width: 375px) {
  ngb-datepicker.dropdown-menu.show {
    transform: translate(0px, 52px) !important;
    width: 100%;

    .ngb-dp-header {
      .ngb-dp-arrow {
        flex: none;
      }
      .ngb-dp-navigation-select {
        flex: none;
        flex-direction: column;
      }
    }

    ngb-datepicker-navigation {
      justify-content: space-between;
      width: 100% !important;
    }
  }

  .input_sm_150px {
    width: 150px !important;
  }
}
@media (max-width: 600px) {
  h2.color-light-red {
    font-size: 30px !important;
    margin-top: 30px !important;
  }
}
@media (max-width: 768px) {
  .card-body {
    .links > .btn-blue {
      display: block;
      margin-bottom: 20px !important;
      padding: 0.8em 1em !important;
    }
  }
}
@media (max-width: 1024px) {
  .modal.show .modal-dialog {
    top: 11% !important;
  }
}
@media (max-width: 280px) {
  .myaccordion {
    button.btn-collapse {
      justify-content: center !important;
    }
  }
}

.courseTransaction {
  @media (min-width: 768px) and (max-width: 991.98px) {
    table.dataTable {
      width: 100% !important;
    }
  }
  .custom-select {
    width: 90px !important;
    height: 50px !important;
    padding: 0.9rem !important;
    border-radius: 3px !important;
  }
}

// End Genral Media ____________________________________________________

// landscape Media ____________________________________________________

@media screen and (orientation: landscape) and (min-device-width: 319px) and (max-device-width: 480px) {
}

.custom-select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-image: url('../images/arrow-down.svg');
  background-repeat: no-repeat;

  background-size: 12px;
  height: calc(1.5em + 0.75rem + 24px);
  // box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
  border: 1px solid #e1d9d9;
  border-radius: 50px;
  background-color: #ffffff;
  padding: 1em;
  &:hover {
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
  }
}
.cst-BorderRadius {
  border-radius: 50px;
}
.date-input {
  .input-group {
    .form-control {
      height: calc(1.5em + 0.75rem + 24px);
      &:hover {
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
      }
      &:not(:last-child) {
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
      }
      &:not(:first-child) {
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
      }
    }
  }
}

.system-signup-button {
  border-radius: 30px;
  background-color: #5be2bf;
  padding: 10px 20px 13px 20px;
  color: white;
  font-weight: 500;
  letter-spacing: 0.75px;
}

.customSelect {
  width: 90px !important;
}





//  How it work
.howItWork {

  .how-does-it-work-page {
    padding-top: 35px;
  }
  h1,
  h2,
  h3 {
    font-weight: 600;
    width: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    margin: 0 auto;
    border-bottom: 4px solid #5be2bf;
    padding-bottom: 10px;
    margin-bottom: 25px;
  }
  .system-theme-second-backgoround-color {
    background-color: #0a3d62;
    padding: 50px 0;
    color: #fff;
  }

  .bg-livelearn {
    color: #FFF;
    background-image: url('../images/how-it-works/livelearn-bg.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    &-contentTitle {
      color: #FFF;
      font-size: 1.3em;
      max-width: 500px;
      margin: 0px auto;
      font-weight: 600;
      line-height: 1.8em;
    }
  }
  
  .bg-livelearn02 {
    background-image: url('../images/how-it-works/livelearn-bg02.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
}


/*-------------------------------------------------------------------------
                        ratings style starts
-------------------------------------------------------------------------*/

/* Rating Star Widgets Style */

.rating-stars ul {
  list-style-type: none;
  padding: 0;
  -moz-user-select: none;
  -webkit-user-select: none;
  > li.star {
    display: inline-block;
    > i.fa {
      font-size: 1em;
      color: #ccc;
      width: 1em;
    }
    &.hover > i.fa,
    &.selected > i.fa {
      color: #f9c132;
    }
  }
}


.rating-stars {
  .star {
    position: relative;
    display: inline-block;
    color: #ffa500;
    margin-right: 0;
    font-size: 12px;
    padding: 0 1px;
  }
  &.font-xs {
    li i,
    span {
      font-size: 1em !important;
    }
  }
  &.font-lg li i {
    font-size: 1.5em !important;
  }
}

/*-------------------------------------------------------------------------
                        ratings style ends
-------------------------------------------------------------------------*/