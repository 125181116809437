
/*************** Start FAQ-questions-section **************/

.FAQ-questions-section {
  background-color: #f6f6f6;
  padding: 60px 10px;
  @media(min-width: 426px){
    padding: 60px 50px;
  }
  .faq-title {
      color: #0a3d62;
      font-weight: 600 !important;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      border-bottom: 4px solid #5be2bf;
      padding-bottom: 10px;
      margin: 0 auto 25px;
  }
  .faqsContainer {
    &-title {
      color: #0a3d62;
      border-bottom: 2px solid #5be2bf;
      font-size: 24px;
      font-weight: 600;
      width: fit-content;
      text-transform: capitalize;
    }
    .WhoWeAre {
      font-size: 20px;
      width: 80%;
    }
  }
}
.accordionContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.accordion {
    width: 100%;
    padding: 1.2rem 0;
    border-radius: 1rem;
    @media(min-width: 426px){
      width: 90%;
    }
    @media(min-width: 769px){
      width: 70%;
    }

    &__btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 1.2rem .4rem;
      background: #0a3d62;
      border: none;
      outline: none;
      color: #FFF;
      font-size: 1.2rem;
      text-align: left;
      cursor: pointer;
      transition: all 0.1s ease-in-out ;
      &:hover {
        color: #0a3d62;
        background: hsl(248, 53%, 97%);
      }
      @media (min-width: 426px) {
        padding: 1.2rem 1.4rem;
      }
      .fa-lightbulb {
        padding:0 0.5rem;
        @media(min-width: 426px){
          padding:0 1rem;
        }
      }
    }
    &__content {
      background: #FFF;
      font-weight: 300;
      max-height: 0;
      opacity: 0;
      overflow: hidden;
      color: hsl(248, 10%, 40%);
      margin: 0;
      // padding: 0;
      list-style: decimal;
      transform: translateX(16px);
      transition: max-height 0.5s ease, opacity 0.5s, transform 0.5s;

      li {
        padding: 1rem 0.5rem;
        font-size: 18px;
        font-family: 'Almarai', sans-serif !important;
      }
    }
    &__caption {
      font-size: 22px;
    }
    &__icon {
      border-radius: 50%;
      transform: rotate(0deg);
      transition: 0.3s ease-in-out;
      opacity: 0.9;
    }
    &__item {
      &:not(:last-child) {
        border-bottom: 1px solid lightgrey;
      }
    }
  }
  .accordion__item--active {
    .accordion__btn {
      color: #FFF;
      border-bottom: 2px solid #FFF;
      background: #0a3d62;
    }
    .accordion__icon {
      transform: rotate(135deg);
    }
    .accordion__content {
      opacity: 1;
      transform: translateX(0px);
      max-height: 100vh;
    }
  }
/******************* End FAQ-questions-section ***********/