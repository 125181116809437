
/*-------------------------------------------------------------------------
                        authentication style starts
-------------------------------------------------------------------------*/
.login { 
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    @media(max-width: 768px) {
        justify-content: center;
    }
    &__aside {
        width:50%;
        background-color: #fff;
        // box-shadow: 0 0 40px rgba(177, 187, 208, 0.15);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        
      &--logo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top:20px;
          .login-logo {
            img {
                height: 48px;
            }
          }
        }
        .aside-img {
        //   background-image: url("../images/login/login-image.jpeg");
          min-height: 407px !important;
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
        }
        @media(max-width: 768px) {
            display: none;
        }
    }
    &__content {
        width:50%;
        background-color: #fcfbfb;
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0;
        

        .login-form,
        .signup-form-container {
            width: 95%;
            max-width: 600px;
            border: 1px solid #848d964d;
            padding: 45px 0;
            border-radius: 8px;
            margin: 30px 0;
            .form {
                width:90%;
                margin: 0 auto;
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                
            }
            .show-hide-password-icon-box2 {
                position: relative;
                i {
                    position: absolute;
                    top: 35%;
                    left: 4%;
                }
            }
            .system-login-form-input-box {
                width: 90%;
                // margin: 20px auto;
                border-radius: 3px;
                cursor: pointer;
                font-size: 16px;
                margin-bottom: 20px;
                input {
                    height: 55px;
                    font-size: 0.9rem;
                    &:active,
                    &:focus {
                        box-shadow: none;
                        outline: none;
                        border-color: #ced4da;
                    }
                }
                  .email-input {
                    border-radius: 3px !important;
                    text-align: center;
                    direction: ltr;
                  }
                  
                

                @media (max-width: 576px) {
                    input {
                    padding: 0 5px;
                    }
                    p {
                    margin-bottom: 7px;   
                    }

                    // p.show-hide-password-icon-box2 {

                    //     input {
                    //         width: 85% !important;
                    //     }
                    // }
                }
                @media (min-width: 576px) and (max-width: 768px) {
                    
                }
              }
            ._dflex {
                display: flex;
                justify-content: center;
                @media(max-width: 320px) {
                
                    justify-content: flex-start;
                    flex-wrap: wrap;
                }
            }
            @media(max-width: 768px) {
                width:90%;
            }
            position: relative;
            .back {
                position: absolute;
                top: 2%;
                right:4%;
                color: #e4396a;
                display: none;
                @media(max-width: 768px) {
                    display: block;
                }
            }
            .sub-content {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                flex-wrap: wrap;
                font-size: 16px;
                // @media(max-width: 425px) {
                //     justify-content: space-between;
                // }
                a {
                    margin-inline-start: 15px;
                }
            }
            .btn.btn-default {
                background-color: #e1245a;
                color: #ffffff;
                font-size: 1em;
                font-weight: 500;
                padding: 0.8em 2em;
                border-radius: 25px !important;
                // box-shadow: 0px 5px 10px 0px rgb(57 50 50 / 20%);
              }
        }
    
        .forgot-submit-button {
            width: 50% !important;
            margin: auto;
        }
        @media(max-width: 768px) {
            width:100%;
        }
    }
}

  
  /*-------------------------------------------------------------------------
                          authentication style ends
  -------------------------------------------------------------------------*/
  