/* You can add global styles to this file, and also import other style files */

:root {
  --hearder-color: #0a3d62 !important;
  --paragraph-color: #808080 !important;
}

a {
  cursor: pointer;
}

/* You can add global styles to this file, and also import other style files */

/**************************** Start  Global ****************************/

/**************************** Start comment ****************************/

/**************************** End comment ****************************/

/*********     adding Almarai font *********/

 
/*********     adding Almarai font *********/


h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
a,
input,
textarea,
button,
small,
label,
select,
table,
.tooltip {
  font-family: 'Almarai', sans-serif;
}

/**************************** End  Global ****************************/

/**************************** Start Header ->  Nav-bar ****************************/

a {
  cursor: pointer;
}


/**************************** End  Header ->  Nav-bar ****************************/

/**************************** Start footer section  ****************************/

.footer-logo-link {
  position: relative;
  margin-right: 0 !important;
}

.footer-logo-header {
  color: white;
  text-decoration: none;
}

.footer-logo-image-div {
  position: relative;
}

.footer-logo-image {
  position: absolute;
  top: 0;
  left: 0;
}

.footer-logo-header2 {
  color: orangered !important;
  text-decoration: none;
  position: absolute;
  top: 104px;
  left: 5px;
  text-transform: uppercase;
  font-size: 11px !important;
  letter-spacing: 1px;
}

.footer-content {
  margin-top: 1rem !important;
  margin-bottom: 2rem !important;
}

.footer-signup-btn {
  padding: 0.8em 4em !important;
  border-radius: 0.2em !important;
  cursor: pointer;
}

.footer-about-header,
.footer-Resources-header,
.footer-Contact-Us-header {
  color: aqua;
  border-bottom: 1px solid #fff;
  padding-bottom: 20px;
  margin-top: 7vh;
  width: 80%;
  font-size: 22px !important;
  margin-bottom: 20px;
  cursor: pointer;
}

.footer-section {
  background-color: #15202b !important;
  padding: 2em 2em 2em 0 !important;
  /* margin-top: 50px; */
}

.footer-social-icons-div .footer-social-icon {
  margin: 0 8px !important;
}

.footer-social-icons-div .footer-social-icon {
  cursor: pointer;
}

.footer-social-icons-div .footer-instagram-icon {
  background: #8a2387;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #f27121, #e94057, #8a2387);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #f27121, #e94057, #8a2387);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.footer-item {
  margin-top: 25px;
  padding: 0 10px;
}

.footer-bottom {
  background-color: #131e29;
  border-top: 1px solid #27394a;
  padding: 1.5em 2em !important;
}

/**************************** End footer section ****************************/

/**************************** Start how-does-it-work-page****************************/

.system-header {
  color: #e4396a;
}

.system-header-div {
  text-align: start !important;
}

/**************************** End how-does-it-work-page ****************************/

/**************************** Start tutors ****************************/

.tutuors-page-header-div {
  padding-bottom: 3em;
  border-bottom: 1px solid #fbf0f0;
  margin-bottom: 3em;
  width: 100% !important;
}

.tutuors-page-header-div-label {
  width: 80% !important;
  margin: 0 auto !important;
  margin-bottom: 20px !important;
}


.tutuors-page .ngb-dp-navigation-select {
  display: contents !important;
}



.tutuors-page .calendar-popup {
  position: absolute;
  top: 50px !important;
  background-color: #fff !important;
  z-index: 10;
  left: 0px !important;
}

.tutuors-page .datepicker {
  margin-top: 15px !important;
}

.tutuors-page .datepicker input {
  height: 46px !important;
  cursor: pointer;
}


/**************************** End tutors ****************************/

/**************************** Start tutors-card-section ****************************/
.calendar-popup {
  position: absolute;
  top: 100%;
  left: 0px !important;
}

/**************************** End tutors-card-section ****************************/

/**************************** Start teach-with-us-page ****************************/

.teach-with-us-page {
  overflow: hidden;
}

.teach-with-us-page .Become-Tutor-btn {
  border-radius: 3px !important;
}

.slick-track {
  margin-left: auto !important;
  margin-right: auto !important;
}

.content-paragraph {
  font-size: 19px !important;
  padding: 0 40px;

}
@media (max-width: 375px) {

  .content-paragraph {
    padding: 0 20px;
  }
}

.start-btn {
  border-radius: 3px !important;
  padding: 10px 60px !important;
}

.banner-sm-img {
  -o-object-fit: cover;
  object-fit: cover;

  right: 61px !important;
  top: -156px !important;
}

/**************************** End teach-with-us-page ****************************/

/**************************** Start topics-list ****************************/


.topics-list-page-filter-input {
  height: 52px !important;
}
.topics-list-page .datepicker {
  height: 52px !important;
  margin-top: 10px !important;
}

.topics-list-page .datepicker input {
  height: 52px !important;
  margin-top: 0px !important;
}

.topics-list-page-label {
  width: 100% !important;
  margin: auto !important;
  margin-bottom: 13px !important;
  /* padding-right: 52px; */
}
/**************************** End topics-list ****************************/

/**************************** Start coursese-page****************************/


.courses-page h2 {
  color: #e4396a;
}
.courses-page-label {
  display: inline-block;
  /* margin-bottom: 0.5rem; */
  width: 100%;
  margin: auto;
  margin-bottom: 15px;
}
/**************************** End coursese-page****************************/


/****************** button up ***********************/
.back-to-top-button {
  opacity: 0;
  position: fixed;
  bottom: 4%;
  right: 15px;
  background-color: #3091f2;
  color: white;
  z-index: 999;
  text-align: center;
  font-size: 20px;
  padding: 10px;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.25);
  cursor: pointer !important;
}

.back-to-top-button {
  text-decoration: none;
  cursor: pointer !important;
}

/****************** button up ***********************/

/**************************** Start categories ****************************/

.step-user .col-md-4:after {
  display: none !important;
}

/**************************** End categories ****************************/

/**************************** Start tutors-subject ****************************/

.tutors-subject-page {
  padding-top: 3rem !important;
  min-height: 100vh;
}

.tutors-subject-page h2 {
  color: #e4396a;
}

/**************************** End tutors-subject ****************************/

/**************************** Start comment ->  comment ****************************/

.top-rated-span-icon {
  background-color: #092644;
  padding-left: 11px;
  padding-bottom: 5px;
  width: 40px;
  position: absolute !important;
  top: 8px;
  left: 6px;
  cursor: pointer;
  border-radius: 2px;
}

.top-rated-span-icon i {
  color: yellow;
  font-size: 14px !important;
}

.top-rated-icon-div {
  background-color: transparent !important;
  position: relative !important;
}

.system-second-card {
  width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
  width: 95% !important;
  height: 434px !important;
  /* overflow: hidden !important; */
  /* border: 1px solid #f3f3f9;*/
}


.card-update-title {
  text-align: start;
  /* padding: 0 10px; */
}

.card-book-button-div {
  height: 40px !important;
}


.No-results-message {
  color: #000 !important;
  text-align: center;

  font-size: 16px;
}

/**************************** End comment ->  comment ****************************/

/**************************** Start categories-page ****************************/

.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 0%) !important;
}

.categories-card-header {
  padding: 6px 30px !important;
  margin-bottom: 0;
  background-color: #e2425a !important;
  border-bottom: 0;
  box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 10%);
  border-radius: 3px !important;
}

.categories-filter-header button {
  color: white !important;
}

.categories-search-box {
  border: 1px solid #8a94af36;
  border-radius: 3px;
}

.categories-search-box input {
  border: none !important;
}

.categories-search-box span {
  border: none !important;
}

.categories-search-box i {
  color: #e2425a;
}

.categories-card-body {
  text-align: start !important;
  padding: 0 2px !important;
}

.categories-card-body {
  text-align: start !important;
  padding: 0 2px !important;
}

.custom-control-label {
  cursor: pointer !important;
}

.custom-control-label:focus,
.custom-control-label:visited {
  border: none !important;
  outline: none !important;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #e2425a !important;

  cursor: pointer !important;
}

.card-category-list .custom-control label {
  width: 100%;
  font-size: 14px !important;
  color: #000000;
  font-weight: 600;
  padding: 0 5px 0 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #44daa2 !important;
  background-color: #44daa2 !important;
}

/**************************** End categories-page ****************************/

/**************************** Start categories-cards-page****************************/

/**************************** End categories-cards-page****************************/

.calendar-popup {
  position: absolute;
  top: 100%;
  left: 0px !important;
}

/**************************** End tutors-card-section ****************************/

/**************************** Start comment ->  comment ****************************/
.categories-second-section .sub-content {
  font-size: 16px;
}
/**************************** End comment ->  comment ****************************/

/**************************** Start  categories-testimonial-section ****************************/

/**************************** End  categories-testimonial-section ****************************/

/**************************** Start Dashboard ****************************/

.pn-Advancer_Left {
  display: none;
}

.dashboard-header-div {
  background-color: white;
  position: sticky;
}

/**************************** End Dashboard ****************************/

/**************************** Start profile-form ****************************/

.form-profile-input-div {
  border-bottom: 1px solid #dee2e696;
  margin-bottom: 5px;
}


.ql-picker-label svg {
  position: absolute;
  margin-top: -9px;
  right: -16px !important;
  top: 50%;
  width: 18px;
}

/**************************** End profile-form ****************************/

/**************************** Start carousel  ****************************/

.system-carousel-card-div {
  padding: 5% 3% 5% 3%;
  text-align: center;
}

/* .owl-theme .owl-nav [class*=owl-] {
  color: #FFF;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #0a3d62 !important;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
  height: 15px !important;
  width: 15px !important;
  border: 1px solid white;
  border-radius: 50% !important;
  margin: 18px 6px;
} */

.owl-theme .owl-nav [class*='owl-']:hover {
  background: #071e38 !important;
  color: #fff;
  text-decoration: none;
}

.pn-ProductNav_Link {
  padding: 15px !important;
}

/**************************** End carousel  ****************************/

/**************************** Start conversation ****************************/

/* .conversation-header {
  padding: 0 10px !important;
} */

.messages-input-box {
  padding: 0 7%;
}

.messages-button i {
  font-size: 15px !important;
}

.invite-header {
  float: start;
}

/**************************** End conversation ****************************/

/**************************** Start profile-resume-certificate-modal ****************************/

.modal-dialog-centered {
  margin-top: 8% !important;
  /* width: 45% !important; */
}

.profile-resume-certificate-modal-button {
  color: #fff;
  background-color: #2ecc71 !important;
  border-color: #2ecc71 !important;
}

.close-button {
  border: none !important;
  background-color: transparent !important;
  color: #e1245a;
  font-size: 14px;
  margin-top: 12px;
}

.close-button:focus-visible {
  border: none !important;
  outline: none !important;
}

.close-button:focus-visible i {
  border: none !important;
  outline: none !important;
}

.users-profile-edit-button i {
  color: #333 !important;
  text-decoration: none;
  font-size: 14px;
  margin-bottom: 5px;
}

.users-profile-edit-button:hover i {
  color: #333 !important;
}

.users-profile-delete-button i {
  font-size: 14px !important;
}

/**************************** End profile-resume-certificate-modal ****************************/

/**************************** Start system-modal-header ****************************/

.avatar-close-button-div {
  /* margin-left: -60px; */
  position: relative;
}

.avatar-close-button {
  /* margin-left: -60px; */
  position: absolute;
  left: -65px;
  top: -10px;
}

.custom-file-upload {
  border: 1px solid #d3dbe2 !important;
  background-color: #f5f5f5;
  padding: 1em;
  height: 60px !important;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333 !important;
  font-size: 14px;
  border-radius: 0.3em;
  margin: 10px 0px !important;
  cursor: pointer;
}

.favourite-button {
  box-shadow: none !important;
}

.pn-ProductNav_Link:hover {
  color: #e3455a;
  border-bottom: 3px solid #e3455a !important;
  padding: 15px !important;
  transition: all 0.2s ease !important;
}

.users-topics-add-button {
  width: 30%;
  border-radius: 2px !important;
}

/**************************** End system-modal-header ****************************/

/**************************** Start courses ****************************/

.courses-section-div .cate-profile {
  border-radius: 100%;
  width: 20% !important;
}

.teachers-img img {
  width: 40% !important;
  margin: 0px auto;
  margin-bottom: -6em;
  border-radius: 100%;
  box-shadow: 0px 4px 0px 6px #f0f0f0;
}

.courses-section-text-div {
  margin: 25px 0;
}

/**************************** End courses ****************************/

/**************************** Start Verified-Teachers ****************************/

.teachers-img img {
  width: 27% !important;
  margin: 0px auto;
  margin-bottom: -4em !important;
  border-radius: 100%;
  box-shadow: 0px 4px 0px 6px #f0f0f0;
}

/**************************** End Verified-Teachers ****************************/

/**************************** Start testimonials ****************************/

.testimonials-card-div {
  background-color: yellow;
  border: 1px solid black;
}

.testimonials-image-div {
  padding: 5%;
}

.testimonials-image {
  max-height: 80vh;
  margin: 5%;
}

.testimonials-card {
  padding: 10px;
}

/**************************** End testimonials ****************************/

/**************************** Start courses-categories-section ****************************/




.courses-section-card .cate-profile {
  border-radius: 100%;
  width: 80px !important;
  margin-bottom: 32px;
}

/**************************** End courses-categories-section ****************************/

/**************************** Start system-main-card ****************************/


.system-main-paragrapgh {
  font-family: 'Tajawal', sans-serif !important;
}

.morelink {
  display: inline-block;
  color: #ffffff !important;
  font-size: 1.1em;
  font-weight: normal !important;
  border: 1px solid #ffffff;
  padding: 5px 20px;
  background-color: #0a3d62;
  border-radius: 2px;
  /* width: 30%; */
  /* margin: 0 100px; */
  margin-left: 6px;
}

.card-text p {
  font-size: 15px !important;
  color: black !important;
  font-weight: normal !important;
}


.morelink {
  display: inline-block;
  color: #ffffff !important;
  font-size: 1.1em;
  font-weight: normal !important;
  border: 1px solid #ffffff;
  padding: 5px 25px;
  background-color: #e1247a;
  border-radius: 25px;
  /* width: 30%; */
  /* margin: 0 100px; */
  margin-left: 6px;
}

.card-text p {
  font-size: 14px !important;
  color: black !important;
  font-weight: normal !important;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}



h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500 !important;
  position: relative;
  line-height: 40px;
}


.color-light-black {
  color: #808080 !important;
}

/**************************** End system-main-card ****************************/

/**************************** Start Verified-Teachers ****************************/



.profile {
  padding-top: 80px;
  padding-bottom: 30px;
  padding-left: 40px;
  padding-right: 40px;
  cursor: pointer;
  margin: 0 10px;
  width: 95%;
}

.active2 {
  color: white !important;
  background: #0a3d62 !important;
  box-shadow: 0 7px 7px #333 !important;
}

.profile:hover {
  color: white !important;
  background: #0a3d62;
  transition: 0.9s;
  box-shadow: 0 20px 20px #333;
}

.profile h5 {
  padding: 20px 0;
  /* border-bottom: 2px solid white; */
  border-bottom-width: thin;
  text-transform: uppercase;
  width: 75%;
  margin: auto;
}

.client {
  width: 120px !important;
  height: 120px !important;
  border-radius: 50%;
  position: absolute;
  top: -60px;
  left: 31.33333%;
  border: 0px solid #fff;
}

blockquote {
  padding: 30px;
  padding-left: 25px;
}

blockquote:before {
  display: block;
  font-size: 700%;
  content: open-quote;
  height: 1px;
  margin-left: -2.2em;
  position: relative;
  top: -50px;
}

blockquote:after {
  display: block;
  font-size: 700%;
  content: close-quote;
  height: 1px;
  margin-left: 1.45em;
  position: relative;
  top: -64px;
}



.stars {
  width: 80% !important;
}


.teacher-card-paragraph {
  font-size: 13px;
  padding: 0 5px;
  text-align: justify;
}





.content-title {
  color: #767676;
}



/**************************** End Start Verified-Teachers ****************************/

/**************************** Start students-opinions ****************************/

.students-opinions-profile-div {
  height: 53vh !important;
}

.students-opinions-profile-div .profile {
  height: 100% !important;
  background: #0a3d62;
  color: white;
}

.students-opinions-video-div {
  height: 53vh !important;
}

.profile {
  color: white !important;
  background: #0a3d62 !important;
  transition: 0.9s;
  box-shadow: 3px 3px 20px 3px #0a3d62;
}

.testimonials-card .card-body {
  background-color: #0a3d62;
  color: white;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/**************************** End students-opinions ****************************/

/**************************** Start Hompage Enhancement ****************************/

/**************************** End Hompage Enhancement ****************************/

.Courses-row {
  padding-top: 25px;
}

/**************************** Start carousel ****************************/

.owl-theme .owl-nav [class*='owl-'] {
  color: #10ac84 !important;
  font-size: 8px;
  margin: 10px 20px !important;
  /* padding: 11px; */
  background: #ffffff;
  display: inline-block;
  cursor: pointer;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  line-height: 2 !important;
  border: 1px solid #10ac84 !important;
  transition: all 0.3s ease-in-out;
}

.owl-theme .owl-nav [class*='owl-'] i {
  padding: 9px 0;
}

.owl-theme .owl-nav [class*='owl-']:hover {
  background: #10ac84 !important;
  color: #fff !important;
  text-decoration: none;
}

p,
h5,
h6 {
  font-family: 'Tajawal', sans-serif !important;
}

.btn .btn-default {
  font-weight: 500 !important;
}

.morelink {
  display: inline-block;
  color: #ffffff !important;
  font-size: 1.1em;
  font-weight: normal !important;
  border: 1px solid #ffffff;
  padding: 5px 25px;
  background-color: #10ac84 !important;
  border-radius: 25px;
  margin-left: 6px;
}


.teacher-card-paragraph {
  font-size: 12px;
  padding: 0 5px;
  text-align: justify;
  color: white !important;
}

.profile h5 {
  padding: 20px 0 0 0 !important;
  /* border-bottom: 2px solid white; */
  border-bottom-width: thin;
  text-transform: uppercase;
  width: 75%;
  margin: auto;
  color: white !important;
}

.profile p {
  color: white !important;
}

.card h6 {
  color: white !important;
}

/**************************** End carousel ****************************/

/**************************** Start comment ****************************/

.morelink {
  padding: 2px 15px;
  font-size: 13px !important;
  background-color: #3ed0aa !important;
  border-radius: 1px;
}



.star {
  position: relative;
  display: inline-block;
  color: white !important;
  margin-right: 0;
  font-size: 9px !important;
  padding: 0 2px !important;
}

.avatar-profile[_ngcontent-etj-c234] img[_ngcontent-etj-c234] {
  width: 60px !important;
  display: inline-block !important;
  border-radius: 50%;
  position: absolute;
  top: -30px;
  right: 0px;
  max-width: unset !important;
}



.footer-bottom {
  background-color: #0a283e !important;
  border-top: 1px solid #ffffff14;
  padding: 1.5em 2em !important;
}

/**************************** End comment ****************************/

/**************************** Start  pages-how-does-it-work ****************************/

/**************************** End  pages-how-does-it-work ****************************/

.show-scrollTop {
  opacity: 1;
  transition: all 0.2s ease-in-out;
  z-index: 999999999;
  background-color: #5be2bf !important;
}

.courses-section-text-div {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.teacher-card-paragraph {
  height: 20vh;
  font-size: 14px;
  /* max-height: 19vh; */
  overflow: hidden;
  text-align: justify;
  /* border-bottom: 1px solid white; */
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.teacher-card-text h6 {
  word-spacing: 4px;
  color: white !important;
  font-size: 16px !important;
  font-family: 'Almarai' !important;
  text-transform: capitalize;
  letter-spacing: 1px;
}


body {
  overflow-x: hidden !important;
}

.system-price-div {
  /* margin-left: -29px; */
  cursor: pointer;
  padding-top: 10px;
  padding-left: 22px !important;
}

#pnProductNavContents {
  overflow-x: scroll !important;
  padding-bottom: 4px;
}

/* width
::-webkit-scrollbar {
  width: 0px;
}

/* Track */
/* ::-webkit-scrollbar-track {
  background: #f1f1f1;
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: #053b63 !important;
} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #5be2bf !important;
} */

.upload-div {
  width: 100%;
  max-height: 400px;
  overflow: hidden;
  text-align: center !important;
}

.upload-img-div {
  max-height: 280px;
  border: 1px solid #e9ecef;
}

.upload-img-div img,
.upload-img-div video {
  height: 270px;
}

/**************************** Start header ****************************/

.header-user-icon {
  font-size: 14px;
  color: #007bff;
  padding: 0 3px;
}

.header-logout-icon {
  font-size: 14px;
  color: #dc3545;
}

.dropdown-menu {
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 2px !important;
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%);
}

/**************************** End header ****************************/

.modal-close-btn {
  border: 1px solid #dc3545;
  background-color: #dc3545 !important;
  width: 40px;
  height: 40px;
  color: white;
  /* font-size: 19px; */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-close-btn span {
  font-size: 35px;
  padding-bottom: 8px;
}

.modal-dialog .modal-content {
  position: absolute !important;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
  top: 0px !important;
}

.modal-container-div {
  position: relative;
  top: 0;
  /* margin-top: -95px; */
  background-color: white;
  z-index: 999999999999999;
  border-radius: 2px;
}

.data-table .form-control {
  display: block;
  width: 100% !important;
  height: calc(1.5em + 0.75rem + 15px);

  padding-top: 1em;
  padding-right: 15px;
  padding-bottom: 1em;
  padding-left: 15px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #808080;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 3px !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: 0px 4px 10px 0px rgb(0 0 0 / 10%);
  /* padding: 1em 15px !important; */
}

.data-table .customSelect {
  width:90px !important;
}

.star {
  position: relative;
  display: inline-block;
  color: #ccc !important;
  margin-right: 0;
  font-size: 9px !important;
  padding: 0 2px !important;
}


.faqs-container-div h1 {
  color: var(--hearder-color);
  font-weight: 600 !important;
  width: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  border-bottom: 4px solid #5be2bf;
  padding-bottom: 10px;
  margin: 0 auto 25px;
}




/* .faqs-container-div ol {
  background-color: yellow;
} */



/**************************** Start Enhancement ****************************/

.modal.show .modal-dialog {
  transform: none;
  margin-top: 7% !important;
  /* transform: none; */
  /* transform: translate(0, 88px) !important; */
  /* transform: translateY(-50px) !important; */
  
  /* margin-top: 10% !important; */
  z-index: 99999999999999 !important;
}


.send-message-btn {
  border-radius: 25px !important;
  background-color: #5be2bf !important;

  font-weight: 500 !important;
}


.ngb-dp-today {
  background: yellow !important;
}


i.fa.fa-chevron-left.font-sm.ml-1.system-left-arrow {
  padding: 0px 8px !important;
  font-size: 14px !important;
}

i.fa.fa-chevron-right.font-sm.ml-1.system-right-arrow {
  padding: 0px 8px !important;
  font-size: 14px !important;
}



.teacher-card-paragraph {
  text-align: center;
}

.system-btn-rounded {
  background-color: #e1245a;
  border-radius: 25px !important;
}

/* input.form-control.shadow-sm.ng-untouched.ng-pristine.ng-valid {
  height: 52px !important;
} */

input.form-control.shadow-sm.ng-pristine.ng-valid.ng-touched {
  height: 52px !important;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0.4em !important;
  border-bottom-left-radius: 0.4em !important;
}

p.error {
  color: #ff4a4a;
  text-align: start !important;
}

small,
.small {
  font-size: 83% !important;
  font-weight: 400;
}

.star-rating-num {
  color: black !important;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 3px;
  margin: 0px 8px;
}

/**************************** End Enhancement ****************************/

/**************************** Start users-profile-page ****************************/

.users-profile-page .avatar-edit-icon {
  color: #ffffff;
  font-size: 16px;
  margin: 0 5px;
}

.users-profile-page .avatar-trash-icon {
  font-size: 16px;
  margin: 5px;
}

.users-profile-page .avatar-edit-btn {
  border: 1px solid #5be2bf;
  color: #ffffff;
  font-size: 18px;
  font-weight: 500 !important;
  padding: 13px 20px !important;
  border-radius: 25px;
  background-color: #5be2bf;
}

.system-btn-rounded {
  /* background-color: #e1245a; */
  border-radius: 25px !important;
}


.table-horizontal {
  padding: 0 !important;
}

thead {
  color: white !important;
  font-weight: 500;
  height: 50px;
  /* line-height: 40px !important; */
}

.label.label-light-primary {
  color: #0bb783;
  background-color: #d7f9ef;
  border-radius: 25px !important;
  padding: 6px 15px !important;
}

.pagination {
  margin: 0px auto !important;
  width: fit-content !important;
  padding: 0 !important;
}

table tbody tr {
  /* height: 45px; */
  line-height: 25px !important;
}

.fc-header-toolbar.fc-toolbar.fc-toolbar-ltr thead {
  background-color: transparent !important;
}

.label.label-light-warning {
  color: #f4a031;
  background-color: #fef6dd;
  border-radius: 25px !important;
  padding: 6px 15px !important;
}

.data-table .table td {
  text-align: center;
}
td,
th {
  text-align: center !important;
}

.row.text-center.step-user.row-cols-1.row-cols-md-3 {
  margin-top: 60px;
}

body.pace-done.pace-done.mat-typography {
  overflow-x: hidden;
  /* width: 100vw; */
}

nav#pnProductNav {
  padding-bottom: 0;
}

section.xs-padding.bg-dashboard.profile-form.mt-2.users-profile-page {
  margin-top: 0px !important;
  padding: 80px 0 !important;
}

input.search-bar.form-control.ng-untouched.ng-pristine.ng-valid {
  padding: 0 15px;
}
span.input-group-addon.position-left {
  margin: 0 15px;
}

.table thead th {
  vertical-align: inherit !important;
  border-bottom: 2px solid #dee2e6;
}

.topics-list-page .system-course-date-time-div-container {
  padding-bottom: 5px;
}


.courses-page .courses-list-avatar {
  top: 40px !important;
}

/* /////// */

.datepicker.date.input-group.p-0.shadow-sm.position-relative.align-center {
  width: 30vw;
}

html.js {
  overflow-x: hidden;
}

ngb-datepicker-navigation {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  justify-content: inherit;
  width: 350px !important;
}

ngb-datepicker-navigation select.custom-select {
  margin: 5px 7px !important;
  width: 110px !important;
}

.ngb-dp-months {
  display: -ms-flexbox;
  display: flex;
  justify-content: space-around;
}


canvas.canvasjs-chart-canvas {
  position: inherit !important;
}

.tooltip,
.tooltip-inner {
  /* background-color: #007bff !important; */
  /* color: black !important; */
  /* border-radius: 2px !important; */
  font-weight: 500;
  padding: 5px 10px !important;
  z-index: 99999999 !important;
  line-height: 24px;
  margin: 2px 0 !important;
  font-family: 'Tajawal', sans-serif !important;
}

body.pace-done.mat-typography.modal-open {
  padding-right: 0px !important;
}




.d-flex.align-items-center.px-2 {
  font-family: 'Almarai';
}
.modal-dialog.modal-dialog-centered.modal-lg {
  z-index: 999999999999999 !important;
}

a {
  text-transform: capitalize !important;
  letter-spacing: 1px !important;
}

.card-horizontal.box-conversation.py-5 {
  height: 100vh;
  overflow-y: auto;
  background-color: aliceblue;
  border: 5px solid #0858af00;
  padding: 1em;
}

.system-overflow-scroll {
  height: 100vh;
  overflow-y: auto;
}

.courses-detail-overflow {
  height: 53vh;
  overflow-y: auto;
}

i.fas.ml-1.wizard-arrow.mx--1.fa-angle-double-left {
  margin: 0px 5px !important;
  /* padding-top: 5px; */
}

.wizard-arrow {
  margin: 0 6px !important;
}
/**************************** End users-profile-page ****************************/

/**************************** Start mobile versions ****************************/

@media (min-width: 575.98px) {

  select.custom-select.custom-select-sm.form-control.form-control-sm.mx-3.ng-untouched.ng-pristine.ng-valid {
    margin-top: 0px !important;
  }

  .footer-about-header,
  .footer-Resources-header,
  .footer-Contact-Us-header {
    color: aqua;
    border-bottom: 1px solid #fff;
    padding-bottom: 20px;
    margin-top: 0vh;
    width: 80%;
    font-size: 22px !important;
    margin-bottom: 20px;
    cursor: pointer;
  }

  .footer-logo-header2 {
    top: 70px;
    left: -2px;
  }

  .modal-dialog {
    max-width: 500px;
    margin: 4.75rem auto !important;
  }

}

@media (max-width: 575.98px) {


  .card-horizontal.box-message {
    margin-top: 40px;
  }

  .show-hide-password-icon-box input {
    display: inline !important;
    border: none !important;
    width: 85% !important;
    /* background: red; */
    padding: 5px;
  }

  .table_id_length-search {
    width: 90vw !important;
  }

  .users-transaction-list-div {
    margin: 15px 0;
  }

  .fc .fc-toolbar-title {
    font-size: inherit !important;
    margin: 0;
  }

  .signup-hearder-language {
    display: block !important;
  }

  .users-topics-div {
    margin: 15px 0;
  }

  .users-appointments-div {
    padding-top: 0 !important;
  }


  .input-group {
    margin-right: 0px !important;
  }

  .row.text-center.step-user.row-cols-1.row-cols-md-3 {
    margin-top: 0px;
  }

  section.py-5.categories-second-section {
    padding-bottom: 0 !important;
  }

  .modal-dialog.modal-dialog-centered {
    width: 90% !important;
    /* padding: 0 10px; */
    margin: auto;
  }

  button.close-button.avatar-close-button {
    left: -30px;
    top: -10px;
  }

  .teach-with-us-page .text-div {
    margin-bottom: 100px;
  }

  .teach-with-us-page .banner-sm-img {
    right: 50px !important;
  }

  .profile {
    color: white !important;
    background: #0a3d62 !important;
    transition: 0.9s;
    box-shadow: 0 0px 20px #333;
  }

  .students-opinions-profile-div {
    height: 65vh !important;
    margin-bottom: 25px;
  }

  .footer-logo-header2 {
    color: #fbfcfd !important;
    text-decoration: none;
    position: absolute;
    top: 69px;
    left: -6px;
    text-transform: uppercase;
    font-size: 11px !important;
    letter-spacing: 1px;
  }

  .footer-about-header,
  .footer-Resources-header,
  .footer-Contact-Us-header {
    color: aqua;
    border-bottom: 1px solid #fff;
    padding-bottom: 20px;
    margin-top: 0vh;
    width: 80%;
    font-size: 22px !important;
    margin-bottom: 20px;
    cursor: pointer;
  }



  .signup-padding-top {
    padding-top: 25px !important;
  }

  .signup-paragraph {
    margin-top: 0px !important;
    margin-bottom: 15px !important;
  }


  .tutuors-page-header-div {
    padding-bottom: 1em;
    border-bottom: 1px solid #fbf0f0;
    margin-bottom: 0em;
  }

  .features-section h2 {
    margin-bottom: 0 !important;
  }

  .features-section .border-solid {
    margin-bottom: 20px !important;
  }

  .features-section .row {
    margin-top: 25px !important;
  }

  .join-us-section ul {
    margin-bottom: 0 !important;
  }

  .date-box {
    padding-left: 9px !important;
  }


  .courses-row img.float-right.cate-profile.box-shadow-lg {
    top: -39px !important;
  }
  .datepicker.date.input-group.p-0.shadow-sm.position-relative.align-center {
    width: inherit !important;
  }

  .step-user .col-md-4 {
    margin: 25px 0;
  }

  .categories-fourth-section {
    padding-bottom: 0px !important;
  }
  .client {
    width: 120px !important;
    height: 120px !important;
    border-radius: 50%;
    position: absolute;
    top: -60px;
    left: 36.333%;
    border: 0px solid #fff;
  }
  .footer-logo-header2 {
    color: orangered !important;
    text-decoration: none;
    position: absolute;
    top: 69px;
    left: -6px;
    text-transform: uppercase;
    font-size: 11px !important;
    letter-spacing: 1px;
  }


  .how-it-works.video-play {
    height: 50vh !important;
  }

  .how-it-works .video-play iframe {
    height: 50vh !important;
  }
  .how-it-works .bg-color-light-gray {
    background-color: #f5f5f5;
    padding: 25px 0 !important;
  }

  table.dataTable {
    clear: both;
    margin-top: 6px !important;
    margin-bottom: 30px !important;
    max-width: none !important;
    border-collapse: separate !important;
    border-spacing: 0;
    /* width: 230vw !important; */
    width: max-content !important;
    /* overflow-x: scroll; */
    /* padding-bottom: 30px !important; */
    border-radius: 2px !important;
  }

  .table-container {
    overflow-x: scroll !important;
    padding-bottom: 25px;
  }

  div#table_id_paginate {
    margin: 25px auto !important;
  }
}

@media (min-width: 768px) {
  .Courses-row .btn.btn-green {
    background-color: #0eb582;
    color: #ffffff;
    font-size: 1em;
    font-weight: 600;
    padding: 10px 25px !important;
    border-radius: 2px !important;
    box-shadow: 0px 5px 10px 0px rgb(57 50 50 / 20%);
    /* height: 50px; */
    /* margin-top: 2px; */
  }

}

@media (min-width: 992px) and (max-width: 1200px) {
  .footer-logo-header2 {
    top: 106px;
    left: 7px;
  }
}

@media (min-width: 1200px) {
  .courses-section-text-div {
    margin-bottom: 0 !important;
  }

  .courses-section-text-div {
    margin-bottom: 0 !important;
  }


  .courses-row .btn-green {
    padding: 8px 15px !important;
  }

  /* .courses-section-div {
    padding: 3% !important;
  } */
  .footer-logo-header2 {
    top: 108px;
    left: 0px;
  }
}

/* Small devices (landscape phones, 576px and up) */

@media (min-width: 576px) and (max-width: 767.98px) {
  html {
    overflow-x: hidden;
  }

  img.float-right.cate-profile.box-shadow-lg.courses-list-avatar {
    top: -35px !important;
  }

  .col-lg-6.opinions-conatiner-div {
    margin: 20px 0;
  }

  .datepicker.date.input-group.p-0.shadow-sm.position-relative.align-center {
    width: 679px !important;
    margin: 0px 10px 0 15px;
  }

  .number-step {
    margin: 25px 0;
  }

  .datepicker.date.input-group.p-0.shadow-sm.position-relative.align-center {
    width: 706px !important;
    margin: 0px 0px 0 2px !important;
  }


  .col-md-4.mb-sm-5.dashboard-card:first-of-type {
    margin-top: 35px;
  }

  .modal-dialog-centered {
    margin-top: 8% !important;
    width: 70% !important;
    padding: 25px 0px;
  }

  /* .profile-form-button-div {
    text-align: center;
  } */

  .col-sm-12.col-md-3.col-xl.users-topics-div.text-right {
    margin: 20px 0;
  }

  .col-sm-12.col-md-3.col-xl.users-topics-div.text-right label {
    padding: 20px 0 10px 0 !important;
  }

  label.text-center.mb-3.topic-listing-label.text-right {
    padding-top: 15px;
  }

  .table-container {
    overflow-x: scroll !important;
    padding-bottom: 25px;
  }

  table.dataTable {
    clear: both;
    margin-top: 6px !important;
    margin-bottom: 30px !important;
    max-width: none !important;
    border-collapse: separate !important;
    border-spacing: 0;
    width: max-content !important; /* overflow-x: scroll; */
    border-radius: 2px !important;
  }

}

@media (min-width: 768px) and (max-width: 991.98px) {

  .embed-responsive.embed-responsive-16by9.video-frame.students-opinions-video-div {
    margin-right: 10px;
    margin-top: 20px;
  }

  html.js {
    overflow-x: hidden !important;
  }

  .table-container {
    overflow-x: auto;
  }
  table.dataTable {
    clear: both;
    margin-top: 6px !important;
    margin-bottom: 30px !important;
    max-width: none !important;
    border-collapse: separate !important;
    border-spacing: 0;
    width: max-content !important; /* overflow-x: scroll; */
    /* padding-bottom: 30px !important; */
    border-radius: 2px !important;
  }
}
@media (min-width: 991.98px) and (max-width:1025px) {
  .table-container {
    overflow-x: auto;
  }
  table.dataTable {
    clear: both;
    margin-top: 6px !important;
    margin-bottom: 30px !important;
    max-width: none !important;
    border-collapse: separate !important;
    border-spacing: 0;
    width: max-content !important; /* overflow-x: scroll; */
    /* padding-bottom: 30px !important; */
    border-radius: 2px !important;
  }
}

/**************************** End mobile versions ****************************/

.width-fit-content{
  width: fit-content !important;
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
}