body[dir="rtl"] {

  .course-content-photo {
    margin-left: 0px;
    margin-bottom: 16px;
    @media (min-width: 768px) {
      margin-left: 16px;
      margin-bottom: 0px;
    }
  }
  .course-content-courseInfo {
    li {
        img {
          margin-left: 0.5rem;
        }
    }
  }
  .price-discount {
      .fa-tag {
          margin-right: 0.5rem;
      }
  }
  .actionItems {
    .wishlist {
        margin-right: 0.5rem;
    }
  }
  .continue {
    .fa-long-arrow-alt-left {
      margin-left: 0.5rem;
    }
  }
  .shoppingCart-coupons {
    text-align: right;
  }

  .shoppingCart-carts .coursesList .continue {
    text-align: right;
    i.fas {
      transform: rotate(180deg);
    }
  }


  // header 
  .header {
    .nav-link {
      &.dropdown-toggle {
        &::after {
          margin-right: 0.355em;
        }
      }
    }
  }


  // cartIcon 
  .system-add-to-buy-div {
    margin-left: 20px;
  }

  .customCartMenu {
    left:0;
    right: auto;
  }

  .courseInfo {
    span {
      margin-right: 10px;
    }
  }
  // custome margin in header
  .customNav {
    margin-right: 0;
    @media(min-width: 769px) {
      margin-right: 50px;
    }  
  }
  .navbar-menu {
    @media(min-width: 769px) {
      right: inherit;
    }
    right: -200%;
    &.active {
      right: -1%;
    }
  }

  .navbar-menu.active .dropdown-menu {
    right: 70px;
 }

  // cartNumber 
  .cartMenuNumber {
    right:-3px
  }

    /* Courses */
/******* Card Topic *******/
.card-topic .card-topic__badge-tag {
    border-radius: 0 8px 0 0;
  }
  
  .card-topic .card-topic__content .teacher-image {
    right: 5px;
  }
  
  .card-topic-title {
    /* padding: 0 80px 0 0 !important; */
    text-align: right;
  }
  
  
  i.fa.fa-chevron-right.font-sm.ml-1.system-right-arrow {
    display: none;
    padding: 0px 10px !important;
    font-size: 14px !important;
  }
  
  
  
  @media (max-width: 575.98px) {
    li.nav-item.mx-4.text-right {
      display: none;
    }
  
    .card-topic-title {
      /* padding: 0 80px 0 0 !important; */
    }
  
    .teacher-name-div-responsive {
      padding: 0 20% 0 0 !important;
    }
  
    .sm-padding.data-table.ng-star-inserted {
      padding: 2em 0 !important;
    }
  
  
  
  }
  
  @media (min-width: 768px) and (max-width: 991.98px) {
    .embed-responsive.embed-responsive-16by9.video-frame.students-opinions-video-div {
      margin-right: 10px;
      margin-top: 20px;
    }
  
  
    
    /* div#testimonial-slider {
      padding-left: 25px;
    } */
  }
  
  
  
  
  /* For ShowToolTip */
  @media (max-width: 768px) {
    .showTooltip {
      top:1px;
      right:140px;
      width: max-content;
    }
  }
  @media (max-width: 425px) {
    .showTooltip {
      top:62px;
      right:-80px;
      width: fit-content;
    }
  }
  
  @media (min-width: 769px) {
    .showTooltip {
      top:0px;
      right: 170px;
      width: max-content;
    }
  }
  
  
  
  /* For CustomSelect */
  .custom-select {
    background-position: left 25px top 20px;
  }
  .ngb-dp-navigation-select {
    .custom-select {
      background-position: left 12px top 8px;
    }
  }
  
  .search-div .fa-search {
    left: 12%;
  }
  
  
  
  
  
}