.shoppingCart {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  background: #f6f6f6;
  @media (min-width: 1024px) {
    flex-direction: row;
  }
  &-carts {
    background: #fff;
    width: 100%;
    height: auto;
    padding: 40px 20px;
    @media (min-width: 1024px) {
      width: 65%;
      height: 100%;
    }
    .cartsContent {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 30px;
      border-bottom: 1px solid #ccc;
      &-title {
        color: #000;
        font-weight: bold !important;
        font-size: 22px;
        margin: 0;
      }
      &-itemsNumber {
        color: #7d7d7d;
        font-weight: bold;
        font-size: 20px;
      }
    }
    .coursesList {
      padding: 0;
      list-style: none;
      margin: 0;

      .course {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        padding: 20px 0px;
        border-bottom: 1px solid #ccc;
        &-content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          margin-bottom: 20px;
          @media (min-width: 768px) {
            margin-bottom: 0;
            flex-direction: row;
          }
          &-photo {
            width: 110px;
            height: 110px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          &-courseInfo {
            padding: 0;
            list-style: none;

            li {
              margin-bottom: 10px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              p {
                margin: 0;
                line-height: 1em;
              }
              img {
                width: 20px;
              }
              .courseName {
                margin-bottom: 0;
                color: #000;
                font-size: 18px;
                font-weight: bold;
                @media (min-width: 768px) {
                  font-size: 22px;
                }
              }
              .teacherName,
              .className,
              .courseType {
                color: #a7a7a7;
              }
            }
          }
        }
        &-actions {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          @media (min-width: 768px) {
            align-items: flex-end;
          }
          .actionItems {
            list-style: none;
            padding: 0;
            display: flex;
            align-items: center;

            .action {
              a {
                &.remove {
                  color: $danger;
                  &:hover {
                    text-decoration: underline;
                    color: $danger;
                  }
                }
                &.wishlist {
                  color: #a7a7a7;
                  &:hover {
                    color: #a7a7a7;
                  }
                }
              }
            }
          }
        }
      }
      .continue {
        margin-top: 70px;
        span {
          color: #a7a7a7;
          font-size: 18px;
        }
        i.fas {
          color: #7d7d7d;
        }
      }
    }
  }
  &-coupons {
    background: #f6f6f6;
    width: 100%;
    height: auto;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    @media (min-width: 1024px) {
      width: 35%;
      height: 100vh;
    }
    &-title {
      color: #000;
      font-weight: bold !important;
      font-size: 22px;
      padding-bottom: 30px;
      border-bottom: 1px solid #ccc;
      width: 100%;
      margin-bottom: 30px;
    }
    .promocodeContainer {
      &-total {
        margin-bottom: 50px;
        &-title {
          color: #000;
          font-weight: bold !important;
          font-size: 21px;
          margin-bottom: 20px;
        }
      }
      &-promo {
        margin-bottom: 40px;

        .promoTitle {
          color: #000;
          font-weight: bold !important;
          font-size: 22px;
        }
        .promoGroup {
          &-text {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 280px;
            flex-wrap: wrap;
            input {
              height: 45px;
              width: 170px;
              cursor: text !important;
            }
            .btn-apply {
              border-radius: 4px !important;
              height: 45px;
              box-shadow: none;
              background: #10ac84;
              color: #fff;
            }
          }
          &-code {
            display: flex;
            align-items: center;
            .fa-times {
              color: $danger;
              margin-right: 15px;
              // html[dir='ltr'] {
              // }
              // html[dir='rtl'] {
              //   margin-left: 15px;
              // }
            }
            span {
              font-weight: bold;
              font-size: 21px;
            }
          }
        }
      }
      &-cost {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        border-top: 1px solid #ccc;
        margin-bottom: 25px;
        padding-top: 15px;
        h5 {
          font-size: 21px;
          font-weight: bold !important;
        }
        p {
          margin: 0;
          font-weight: bold;
          font-size: 21px;
          color: #10ac84;
        }
      }
      &-checkout {
        .btn-checkout {
          width: 40%;
          background: #10ac84;
          color: #fff;
          border-radius: 3px !important;
          box-shadow: none;
          text-align: center;
        }
      }
    }
  }
  &-noData {
    height: 39vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    .noData-img {
      width: 250px;
      height: 250px;
      margin-bottom: 30px;
    }
    .text {
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        margin: 0;
        color: #000;
        font-size: 16px;
        font-weight: bold;
      }
      a {
        font-size: 16px;
        font-weight: bold;
        color: #000;
        &:hover {
          text-decoration: underline;
          color: #10ac84;
        }
      }
    }
  }
}

.price {
  margin-bottom: 20px;
  &-discount {
    color: #10ac84;
    font-weight: bold;
    font-size: 18px;
  }
  &-actual {
    text-decoration: line-through;
    color: #c8c8c8;
    font-size: 18px;
  }
}



