body[dir="ltr"] {
    

    // ShopList
    .course-content-photo {
      margin-right: 0px;
      margin-bottom: 16px;
      @media (min-width: 768px) {
        margin-right: 16px;
        margin-bottom: 0px;
      }
    }
    .course-content-courseInfo {
        li {
            img {
            margin-right: 0.5rem;
            }
        }
    }
    .price-discount {
        .fa-tag {
            margin-left: 0.5rem;
        }
    }
    .actionItems {
        .wishlist {
            margin-left: 0.5rem;
        }
    }
    .continue {
      .fa-long-arrow-alt-left {
        margin-right: 0.5rem;
      }
    }
    .shoppingCart-coupons {
      text-align: left;
    }

    .shoppingCart-carts .coursesList .continue {
      text-align: left;
    }


    // header 
  .header {
    .nav-link {
      &.dropdown-toggle {
        &::after {
          margin-left: 0.355em;
        }
      }
    }
  }

    
  // cartIcon 
  .system-add-to-buy-div {
    margin-right: 20px;
  }


  .navbar-menu {
    left: -200%;
    @media(min-width: 769px) {
      left: inherit;
    }
    &.active {
      left: -1%;
    }
  }
  .customCartMenu {
    right:0;
    left: auto;
  }
  .courseInfo {
    span {
      margin-left: 10px;
    }
  }
  // custome margin in header
  .customNav {
    margin-left: 0;
    @media(min-width: 769px) {
      margin-left: 50px;
    }   
   }
   .navbar-menu.active .dropdown-menu {
      left: 70px;
   }


   // cartNumber 
  .cartMenuNumber {
    left:-3px
  }


    /* Courses */
/******* Card Topic *******/
.card-topic .card-topic__badge-tag {
    border-radius: 8px 0 0 0;
  }
  .card-topic .card-topic__content .teacher-image {
    left: 5px;
  }
  
  /* Verified Teachers */
  .Verified-Teachers .Verified-Teachers__box--photo {
    left: 30%;
  }
  
  .card-topic-title {
    /* padding: 0 80px 0 0 !important; */
    text-align: left;
  }
  
  
  
  
  
  
  i.fa.fa-chevron-left.font-sm.ml-1.system-left-arrow {
    display: none;
    padding: 0px 10px !important;
    font-size: 14px !important;
  }
  
  /* app-sort {
    position: absolute;
    top: 34px;
    left: 50px;
  } */
  
  @media (min-width: 768px) and (max-width: 991.98px) {
    .embed-responsive.embed-responsive-16by9.video-frame.students-opinions-video-div {
      margin-left: 10px;
      margin-top: 20px;
    }
  
  
    
  
    /* div#testimonial-slider {
      padding-right: 25px;
    } */
  }
  
  
  
  
  /*
  محمد دى كود ليظيط استايل اسم المعلم فى الكارد الكارد لما الموقع يكون انجليزى
  وانا حاطة هما فى الملف الى بيشتغل فى حالة الانجليزى 
  
  ممكن تشتغل ع النظام دا اذا لقيتة افضل 
  */
  /* .card .link-profile span{
  margin-left: 25px;
  } */
  
  /* For ShowToolTip */
  @media (max-width: 768px) {
    .showTooltip {
      top:1px;
      left:130px;
      width: max-content;
    }
  }
  @media (min-width: 769px) {
    .showTooltip {
      top:0px;
      right: 170px;
      width: max-content;
    }
  }
  @media (max-width: 425px) {
    .showTooltip {
      top:62px;
      left:-80px;
      width: fit-content;
    }
  }
  
  /* For CustomSelect */
  .custom-select {
    background-position: right 25px top 20px !important;
  }
  .ngb-dp-navigation-select {
    .custom-select {
      background-position: right 12px top 8px;
    }
  }
  
  .search-div .fa-search {
    right: 12%;
  }
}