
@mixin customTooltip($width, $display, $margin) {
    width: $width;
    // word-break: break-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 $margin;
    display: $display;
  }

.singleLesson {
    &-title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}
.singleLesson--container {
    margin-block: 10px;
    width: 100%;
    transform: translateY(0px);
    transition: all 0.5s ease-in-out;
    &:hover {
      transform: translateY(-10px);
      box-shadow: 0 0 10px #ccc;
    }
    .singleLesson {
      &__card {
        display: flex;
        flex-direction: column;
        border: 1px solid #ccc;
        border-radius: 8px;
        overflow: hidden;
  
        &--cover {
          height: 400px;
          overflow: hidden;
          margin: 0 -110px;
  
          img.card-img {
            display: block;
            width: 100%;
            height: 125%;
          }
          .singlecard-img {
            object-fit: contain;
          }
  
          @media (max-width: 576px) {
            height: 300px;
          }
          @media (min-width: 576px) and (max-width: 768px) {
            height: 300px;
          }
          @media (max-width: 1024px) {
            margin: 0 -120px;
          }
        }
        &--info {
          background: #10ac84;
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
          padding: 15px 8px;
          .singleLesson--title {
            @include customTooltip(180px, block, 0);
            color: #fff;
            text-align: center;
            margin: 5px auto;
            width: 100%;
            white-space: break-spaces;
          }
          .singleLesson--text {
            @include customTooltip(auto, block, 5);
            color: #fff;
            text-align: center;
            margin: auto;
            height: 70px;
            white-space: pre-line;
            line-height: 1.4;
            font-weight: normal;
            // padding:0 15px;
            width: 100%;
          }
        }
      }
    }
  }

.seeMoreAction {
    display: flex;
    justify-content: center;
    align-items: center;

    // Show more Button
    .system-view-button {
        background-color: #0a3d62;
        padding: 12px 35px;
        margin: 15px 0;
        border-radius: 25px;
        transition: all 0.3s ease-in-out;
        font-weight: normal;
        color: #FFF;
        &:hover {
        color: #FFF;
        background-color: #037b7b;
        border-color: #037b7b;
        transform: translateY(-3px);
        }
    }
}


// Starter Title
.starterPage-title {
    color: #0a3d62;
    font-weight: 600 !important;
    width: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
      margin: 0 auto;
    border-bottom: 4px solid #5be2bf;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }


  /**************************** Start How-It-Works-section  ****************************/
  .How-It-Works-div {
    background-color: #0a3d62;
    display: flex;
    justify-content: center;
    align-items: center;
  }
.How-It-Works-section-btn {
    border-radius: 2px !important;
    padding: 10px 45px !important;
  }
  .How-It-Works-section h2,
  .How-It-Works-section p {
    color: white !important;
    font-weight: normal !important;
  }
  /**************************** End How-It-Works-section  ****************************/
  // Courses
/******* Card Topic *******/
/******* Card Course *******/
/******* Same Design *******/

.card-topic {
  position: relative;
  // width:96%;
  width: 100%;
  border-radius: 8px;
  // border: 1px solid #CCC;
  // margin:20px auto;
  transform: translateY(0px);
  transition: all 0.5s ease-in-out;
  box-shadow: 0px 4px 4px 0px rgb(0 0 0 / 10%);
  &:hover {
    // cursor: pointer;
    transform: translateY(-10px);
    // box-shadow: 0 0 10px #ccc;
    .btn-book {
      background-color: #e3455a;
      color: #fff;
    }
  }
  &__badge-tag {
    position: absolute;
    background-color: #fea116;
    width: 48%;
    text-align: center;
    color: #ffffff;
    font-weight: 600;
    font-size: 0.9em;
    height: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    letter-spacing: 0.5em;
    z-index: 999999999;
    // border-radius: 0 8px 0 0;
  }
  &__image {
    position: relative;
    // height: 240px;
    .hyperlink {
      img {
        border-radius: 8px 8px 0 0;
        // height: 28vh !important;
        object-position: center;
      }
    }
    .topic-img {
      height: 240px;
      width: 100%;
    }
  }
  &__content {
    background: #10ac84;
    border-radius: 0 0 8px 8px;
    &--teacher {
      position: relative;
      .teacher-image {
        position: absolute;
        bottom: 10px;

        img {
          width: 60px;
          display: inline-block;
          border-radius: 50%;
        }
      }
      .card-topic-title {
        font-size: 18px;
        a.link-profile {
          font-weight: normal;

          text-decoration: none;
          .card-topic-title--text {
            color: #fff;
            @include customTooltip(180px, inline-block, 85px);
            @media (max-width: 768px) {
              @include customTooltip(180px, inline-block, 85px);
            }
          }
        }
      }
    }
    &--info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 15px;
      .rating-stars-row {
        #stars {
          .star-rating-number {
            color: #fff;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 3px;
            margin: 0px 8px;
          }
        }
      }
      .card-topic-subtitle {
        color: #fff;
        margin-block: 10px;
        @include customTooltip(180px, inline-block, 0px);
      }
      .card-topic-category {
        .card-topic-category-inner {
          background-color: #ffffff;
          height: 50px;
          width: 49%;
          color: #6c757d;
          margin-block: 10px;
          padding: 5px 10px;
          font-size: 12px;
          border: 1px solid #ffffff;
          border-radius: 50px;
          cursor: pointer;
          font-weight: 600;
          .card-subject-category {
            background-color: #fff !important;
            height: 50px;
            width: 49%;
            color: #6c757d !important;
            margin: 0 5px;
            padding: 5px 10px;
            font-size: 12px;
            border: 1px solid #fff !important;
            border-radius: 1px;
            cursor: pointer;
            font-weight: 600 !important;
          }
        }
      }
      .system-course-date-time-div-container {
        .system-course-date-time-div {
          .date,
          .time {
            display: inline-block;
            height: 30px;
            border: 1px solid #0b2a48;
            padding: 0px 11px !important;
            background-color: #0b2a48;
            border-radius: 2px;
            letter-spacing: 3px;
            font-size: 12px;
            color: white;
            border-radius: 50px;
            margin-block: 5px;
          }
          .time {
            margin-inline: 3px;
            @media (max-width: 1024px) {
              margin-inline: 3px;
            }
          }
        }
      }
      .tutorDetails {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        h6 {
          color: #fff;
        }
        &__image {
          .flag-img {
            width: 32px;
            height: 32px;
          }
        }
      }
    }
    &--footer {
      background: #fff;
      border-radius: 0 0 8px 8px;
      width: 100%;
      padding: 0 8px;
      .footer-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 4px;
        flex-wrap: wrap;
        width: 100%;
      }
      .btn-book {
        border: 1px solid #e3455a;
        background: #fff;
        border-radius: 50px;
        padding: 0;
        width: 110px;
        height: 40px;
        line-height: 40px;
        &:hover {
          background-color: #e3455a;
          color: #fff;
        }
      }

      @media (max-width: 280px) {
        .footer-container {
          padding: 20px 0;
          width: 100%;
          align-items: center;
        }

        .btn-book {
          width: auto;
          padding: 10px 10px;
          line-height: 15px;
        }
      }
      .tutor-reservation {
        padding: 15px 0;
        display: flex;
        justify-content: flex-start;
        .tutor-reservation-btn {
          background-color: #0eb582;
          color: #ffffff;
          font-size: 1em;
          font-weight: 600;
          padding: 10px 15px;
          border-radius: 2px;
        }
      }
      .card-price {
        font-size: 14px;
        color: #0a3d62;
        margin-bottom: 0;
      }
    }
  }
}

// Teachers
/******* Verified Teachers *******/
.verify-teach-mt-6 {
  margin-top: 6.5rem !important;
}
.Verified-Teachers {
  padding: 60px 0 !important;
  &__box {
    background: #0a3d62;
    // height: 60vh;
    position: relative;
    padding: 10px 25px;
    width: 100%;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border-radius: 8px;
    transform: translateY(0px);
    box-shadow: 0 0 8px 0px #0a3d62;
    transition: all 0.6s ease-in-out;
    &:hover {
      background: #10ac84;
      color: #fff;
      cursor: pointer;
      box-shadow: 0 0 8px 0px #10ac84;
      transform: translateY(-10px);
      .teacher-name {
        color: #fff;
      }
      .btn-book {
        background: #0a3d62;
        color: #fff;
        border: 1px solid #0a3d62;
      }
    }
    &--photo {
      position: absolute;
      top: -17%;
      left: 35%;
      img {
        width: 120px;
        height: 120px;
        border-radius: 50%;
      }
    }
    &--text {
      margin-block-start: 70px;
      min-height: 160px;
      display: flex;
      flex-direction: column;
      // justify-content: space-between;
      align-items: center;
      width: 100%;

      .teacher-description {
        @include customTooltip(260px, inline-block, 0px);
        white-space: pre-line;
        word-break: break-word;
        // height: 71%;
        width: auto !important;
        margin-bottom: 0;
      }
      .teacher-name {
        color: #fff;
        @include customTooltip(320px, inline-block, 0px);
        margin: 0;
        line-height: 40px;
      }
    }
    &--bookNow {
      .btn-book {
        border: 1px solid #10ac84;
        background: #10ac84;
        border-radius: 50px;
        padding: 0;
        width: 150px;
        height: 40px;
        line-height: 40px;
        color: #fff;
        // &:hover {
        //   background-color: #e3455a;
        //   color:#FFF;
        // }
        i {
          font-size: 14px;
        }
      }
    }
  }

  @media (max-width: 576px) {
    &__box {
      &--photo {
        left: 34%;
      }
    }
  }
  @media (max-width: 280px) {
    &__box {
      &--photo {
        left: 27%;
      }
    }
  }
}
// Custom 
.owl-stage-outer {
  padding:20px;
}

  