/*-------------------------------------------------------------------------
                        Header Top Navbar styles Starts
-------------------------------------------------------------------------*/

.header {
    &.scroll {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 16;
    }
    .nav-link {
      display: block;
      padding: 0.5rem;
      font-size: 1.05em;
      font-weight: 600;
      color: #2f2e41;
      display: flex;
      align-items: center;
      
    }
    .nav-item {
      position: relative;
      &.active .nav-link,
      &:hover .nav-link {
        color: #e1245a;
      }
      
    }
    .dropdown-item {
        position: relative;
        text-align: start;
        padding: 1rem 1.5rem;
        &:active,
        &.active,
        &:hover {
            background-color: #e9ecef;
            color: #e1245a;
        }
        &:hover {
            .showTooltip {
              opacity: 1;
              display: block;
            }
          }
          // ShowToolTip Style
          .showTooltip {
            position: absolute;
            z-index: 99;
            padding: 8px;
            background-color: #000;
            color: #FFF;
            border-radius: 8px;
            text-align: center;
            opacity: 0;
            display: none;
            transition: all 0.5s ease-in-out;
            p {
              margin:0;
            }
          }
          &-link {
              color: $dark;
              &:active,
              &.active,
              &:hover {
                color: #e1245a;
              }
          }
    }
    .navbar {
        padding: 20px;
        background-color: #FFF;
      .logo {
        height: 40px;
      }
      &-collapse {
        justify-content: flex-end;
      }
    }
    .toggleIcon {
      display: block;
      @media(min-width: 769px) {
        display: none;
      }
    }
    .navbar-menu {
      
      
      @media(min-width: 769px) {
        display: flex;
        padding: 0;
        margin: 0;
        list-style: none;
        position: inherit;
        top: inherit;
        right: inherit;
        z-index: inherit;
        background: none;
        height: auto;
        box-shadow: none;
      }
      // order: 3;
      transition: all 5s ease-in-out;
      position: fixed;
      top: 0%;
      
      z-index: 115;
      background: #39ba98;
      height: 110vh;
      padding: 20px;
      box-shadow: 0 0 3px #ccc;
      transition: all 1s ease-in-out;
      list-style: none;
      &.active {
          
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          width: 75%;
          li.nav-item {
              margin-bottom: 30px;
          }
          .dropdown-menu {
            top: 30%;
            min-width: 12rem;
          }
          .nav-link {
            color: #FFF;
          }
      }
      margin-bottom: 0;
    }
    .nav-link i {
      font-size: 1.2em;
    }
    .dropdown-toggle {
        &::after {
            // display: none;
        }
    }
    .language-flag-name,
    .language-name {
      margin-left: 5px;
      margin-right: 5px;
    }
  }
  .respContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 10%;
    flex-direction: row-reverse;
  }
  .information {
      display: none;
      @media (min-width: 767px) {
          display: block;
      }
    .fa-question-circle {
        font-size: 18px;
        font-weight: 100;
      }
  }
//   .informationResp {
//       display: block;
//       @media (min-width: 767px) {
//           display: none;
//       }
//   }

  .cartMenu {
      position: relative;
      .cartMenuNumber {
          position: absolute;
          top:-7px;
          width: 20px;
          height: 20px;
          border-radius: 50px;
          background-color: #e1245a;
          color: #FFF;
          text-align: center;
          line-height: 18px;
      }
  }
  
  .header-profile {
    width: 40px;
    border-radius: 100%;
  }
  
  .navbar-toggle-icon {
    display: block;
    width: 40px;
    height: 3px;
    background: #39ba98;
    margin-bottom:10px;
  }
  
  .dropdown-menu {
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-radius: 0.5em;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1);
  }

  .customCartMenu {
    min-width: 17rem;
    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #CCC;
      .courseInfo {
        color: #979696;
        &-block {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          img {
            width: 20px;
            height: 20px;
          }
          .fa-tag {
            font-size: 16px;
          }
        }
      }
      .fa-trash {
        color: $danger;
        cursor: pointer;
      }
    }
    .buyNow {
      text-align: center;
      &-btn {
        color: $dark;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  
  /*-------------------------------------------------------------------------
                          Header Top Navbar styles Ends
  -------------------------------------------------------------------------*/
  
  .underOverlay {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 114;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0, 0.9);
    overflow-y: auto;
    overflow-x: hidden;
    text-align: center;
    opacity: 0;
    transition: opacity 1s;
    &.activeOverlay {
        width: 100%;
        opacity: 0.8;
    }
    @media(min-width: 769px) {
        display: none;
    }
}
.responsiveIcon {
  display: block;
  @media(min-width: 769px) {
    display: none;
}
}