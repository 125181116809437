
/**************************** Start auth ->  signup ****************************/

.signup-input {
    height: 55px;
    font-size: 0.9rem;
    &:focus {
        box-shadow: none;
        outline: none;
        border-color: #ced4da;
    }
  }
  .signup-hearder {
    display: inline-block;
    padding: 0 10px !important;
    font-size: 16px;
    font-weight: 600;
  }
  
  .signup-sign-button {
    padding: 10px 65px !important;
    border-radius: 3px !important;
  }
  
  .details {
      width: 100%;
  }
  .error {
    color: #ff4a4a;
    font-size: 12px;
    padding: 0 5px;
    margin-top: 5px;
    margin-bottom: 5px !important;
  }
  
 
.ng-select,
.ng-select-single,
.ng-select-container {
    height: 55px !important;
}

.ng-select 
.ng-select-container 
.ng-value-container 
.ng-input>input {
    height: 50px;
}


/**************************** Start show-hide-password-icon ****************************/

.show-hide-password-icon-box {
    background-color: white;
    border: 1px solid #f1ebeb !important;
    border-radius: 3px !important;
  }
  
  .show-hide-password-icon-box input {
    display: inline;
    border: none !important;
    width: 90% !important;
  }
  
  .show-hide-password-icon-box i {
    font-size: 16px !important;
  }
  
  /**************************** End show-hide-password-icon ****************************/

  /**************************** End auth ->  signup ****************************/
  