/*================================
Template Name: 
Author: Ram Nethaji
Author website: http://ramnethaji.com/
Version: 1.0
================================== */

@media (min-width: 576px) and (max-width: 767.98px) {
}

@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (min-width: 992px) and (min-width: 1199.98px) {
}

@media (min-width: 992px) {
}

@media (max-width: 1200px) {
}

@media (max-width: 1199.98px) {
}

@media (max-width: 991.98px) {
  .rtxt-left {
    text-align: left !important;
  }

  .ui-widget-content {
    max-width: 450px;
  }
  h1 {
    font-size: 3em;
    line-height: 1.3em;
  }

  .thumbnail {
    margin-left: 0.5em;
    text-align: center;
  }

  .play-icon {
    left: 42%;
  }

  .video-play {
    min-height: 20em;
  }

  .how-it-works {
    padding: 10px;
  }

  .banner-sm-img {
    position: absolute;
    width: 60%;
    object-fit: cover;
    left: auto;
    right: -10px;
    top: auto;
    bottom: -90px;
    // opacity: 0.5;
  }

  .card-play-icon {
    left: 225px;
    top: 125px;
  }

  .badge {
    margin: 10px;
  }

  .rd-block {
    display: block !important;
  }

  .rtxt-left {
    text-align: left !important;
  }

  .rm-0 {
    margin: 0px !important;
  }

  .rmr-0 {
    margin-right: 0px !important;
  }

  .rmt-1,
  .rmy-1 {
    margin-top: 0.25rem !important;
  }

  .rmt-2,
  .rmy-2 {
    margin-top: 0.5rem !important;
  }

  .rmt-3,
  .rmy-3 {
    margin-top: 1rem !important;
  }

  .rmt-4,
  .rmy-4 {
    margin-top: 1.5rem !important;
  }

  .rmt-5,
  .rmy-5 {
    margin-top: 3rem !important;
  }

  .rmb-1,
  .rmy-1 {
    margin-bottom: 0.25rem !important;
  }

  .rmb-2,
  .rmy-2 {
    margin-bottom: 0.5rem !important;
  }

  .rmb-3,
  .rmy-3 {
    margin-bottom: 1rem !important;
  }

  .rmb-4,
  .rmy-4 {
    margin-bottom: 1.5rem !important;
  }

  .rmb-5,
  .rmy-5 {
    margin-bottom: 3rem !important;
  }

  .rmr-2 {
    margin-right: 0.5rem !important;
  }

  .rp-0 {
    padding: 0 !important;
  }

  .cta-input {
    max-width: 520px;
  }

  .selectpicker {
    margin-left: 0.3em;
  }

  .classes {
    justify-content: start !important;
  }

  .pn-Advancer_Right {
    right: 20px;
  }

  .pn-Advancer_Left {
    left: 20px;
  }

  .tble-responsive {
    // display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .fc {
    .fc-button {
      padding: 0.4em 0.5em;
    }

    .fc-toolbar-title {
      font-size: 1em;
    }
  }

  .filter-label {
    justify-content: start !important;
  }

  .step-user .col-md-4:after {
    background: none;
  }

  .rtxt-center {
    text-align: center;
  }

  .nav-tabs {
    margin-bottom: 1.5em;
  }
}

@media (max-width: 767.98px) {
}

@media (max-width: 575.98px) {
}

@media (min-width: 575.98px) {
}

@media (max-width: 768px) {
}

@media (min-width: 768px) {
}

@media (max-width: 980px) {
}
