
.carousel {
    &-indicators {
        li {
            border: 0;
        }
        @media (max-width: 576px) {
            display: none;
        }
    }
    &-item {
        img {
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 89vh;
            @media (min-width: 575.98px) {
                  height: auto !important;
            }
            @media (min-width: 1200px) {
                height: 89vh !important;
            }
        }
    }
    &-caption {
        top: 0;
        right:0;
        bottom: 0;
        left: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        @media (min-width: 576px) {
            bottom: 35px;
        }
        h1 {
            color: white;
            background-color: #0a3d62bd;
            padding: 0 40px;
            border: 1px solid transparent;
            border-radius: 25px 0;
        }
        p {
        font-size: 16px;
        text-transform: uppercase;
        border-bottom: 4px solid #5be2bf;
        color: black;
        padding: 0 8px;
        background-color: white;
        @media (min-width: 576px) {
            font-size: 24px;
        }
        }
    }
}

.carousel-control-next,
.carousel-control-prev {
    color: #007bff !important;
    font-size: 14px !important;
    z-index: 99;
}
.carousel-control-prev-icon, 
.carousel-control-next-icon {
    display: none;
}

.slider-section {
    @media (max-width: 576px) {
      .carousel-item {
        height: 413px;
        img {
            height: 100%;
        }
      }
      .carousel-caption {
        h1 {
          font-size: 24px;
          padding: 7px 18px;
        }
      }
    }
  }


  
/*------------- Start Search-bar -> search-div -------------*/

.search-div {
    min-height: 50vh;
    padding-top: 65px;
  .banner-area {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
    .banner-heading {
      .dropdown-menu.show {
        border-radius: unset;
        padding: 0;
        border: 1px solid #dddddd;
        position: absolute;
        top: 0px;
        left: 50% !important;
        will-change: transform;
        transform: translate(-50%, 61px) !important;
        border: 1px solid #dddddd;
        width: 80%;
        margin: 0;
        height: 30vh;
        overflow-y: scroll;
      }
      @media (max-width: 576px) {
        .banner-heading {
          h1 {
            font-size: 30px;
          }
          p {
            font-size: 25px;
            line-height: 1.3em;
          }
        }
      }
      @media (max-width: 280px) {
        ::placeholder {
          font-size: 13px;
        }
      }
      .dropdown-item.active,
      .dropdown-item:active {
        color: #000;
        text-decoration: none;
        background-color: #fff;
      }
    }
    h1 {
      color: #0a3d62;
      font-weight: 600;
      width: fit-content;
      margin: 0 auto;
      border-bottom: 4px solid #5be2bf;
      padding-bottom: 10px;
      font-size: 4em;
      // margin-bottom: 25px;
    }
    .search-div-group {
      position: relative;
    }
    .banner-subtitle {
      margin-top: 20px;
      color: #212529;
      font-size: 2em;
    }
    .fa-search {
      color: #46c7a6;
      font-size: 16px;
      position: absolute;
      top: 35%;
    }
  
    input.inp-search {
      width: 80%;
      height: 55px;
      font-size: 14px;
      border-radius: 50px;
      text-indent: 15px;
      cursor: text !important;
    }
  }
  
  /*------------- End Search-bar ->  search-div  -------------*/